import {
  CountryFlagIcon,
  getDeliveryMethodIconKey,
  getManagedAccountDeliveryMethodType,
  isBusinessTypeAllowedForFX,
  useInternationalFxFeature,
  usePaymentProcessingInfo,
} from '@melio/ap-domain';
import { BrandSymbol, BrandSymbolKey, brandSymbolsMap, Icon, IconKey } from '@melio/penny';
import {
  BillerAccountDeliveryMethod,
  Card,
  DeliveryMethod,
  DeliveryMethodByPayor,
  DeliveryMethodType,
  DeliveryPreferenceType,
  FundingSource,
  OrganizationBusinessType,
  PaymentTimelineItem,
  PaymentTimelineItemFlowEnum,
  PaymentTimelineItemStatusEnum,
  useFeeCatalog,
  Vendor,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

export const useDeliveryMethodFee = (fundingSourceType?: FundingSource['type']) => {
  const { data: feeCatalog = [], isFetched } = useFeeCatalog();
  const { formatCurrency, formatPercents } = useMelioIntl();
  const [allowAchToAchFees] = useFeature(FeatureFlags.allowAchToAchFees, false);

  const getCatalogLogEntry = (deliveryMethodType: DeliveryMethodByPayor['type']) => {
    switch (deliveryMethodType) {
      case 'bank-account':
        return feeCatalog.find(
          (fee) => fee.feeType == (allowAchToAchFees && fundingSourceType === 'bank-account' ? 'ach-to-ach' : 'ach')
        );
      case 'paper-check':
        return fundingSourceType === 'bank-account'
          ? feeCatalog.find((fee) => fee.feeType == 'ach-to-check')
          : feeCatalog.find((fee) => fee.feeType == 'check');
      case 'domestic-wire-account':
        return feeCatalog.find((fee) => fee.feeType == 'domestic-wire');
      case 'international-account':
        return feeCatalog.find((fee) => fee.feeType == 'international');
    }
    return void 0;
  };

  return {
    isFetched,
    getFee: (deliveryMethodType: DeliveryMethodByPayor['type']) => {
      const feeCatalogEntry = getCatalogLogEntry(deliveryMethodType);
      if (feeCatalogEntry && feeCatalogEntry?.value > 0) {
        return feeCatalogEntry.valueType === 'fixed'
          ? formatCurrency(feeCatalogEntry.value)
          : formatPercents(feeCatalogEntry.value, { divide: true });
      }
      return null;
    },
  };
};

export const isRppsDeliveryMethod = (deliveryMethod: DeliveryMethod): deliveryMethod is BillerAccountDeliveryMethod =>
  deliveryMethod.type === DeliveryMethodType.BillerAccount;

export const useGetDeliveryMethodHelperText = (
  deliveryMethod: DeliveryMethod,
  timeline: Array<PaymentTimelineItem>,
  countryDisplayName?: string,
  scheduledDate?: Date
): string => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { calculateExpiryDate } = usePaymentProcessingInfo();

  switch (deliveryMethod.type) {
    case 'card':
      return formatMessage(
        `utils.deliveryMethod.${
          (deliveryMethod.details as Card).type === 'debit' ? 'debitCard' : 'creditCard'
        }.helperText`
      );
    case 'bank-account': {
      const tracingNumber = extractTracingNumber(timeline);
      if (tracingNumber) {
        return formatMessage('utils.deliveryMethod.bankAccount.trace', {
          tracingNumber,
        });
      }
      return '';
    }
    case 'virtual-card': {
      const formattedExpiryDate = scheduledDate
        ? formatDate(calculateExpiryDate(scheduledDate), { day: 'numeric', month: 'short' })
        : undefined;
      return formatMessage('utils.deliveryMethod.virtualCard.helperText', { expiryDate: formattedExpiryDate });
    }
    case 'international-account': {
      return formatMessage('utils.deliveryMethod.internationalAccount.helperText', {
        country: countryDisplayName || deliveryMethod.details.identifierDetails.bankCountryCode,
        flag: <CountryFlagIcon countryDisplayName={countryDisplayName} />,
      });
    }
    default:
      return '';
  }
};

const useGetDeliveryPreferenceLabelText = (
  deliveryPreferenceType: DeliveryPreferenceType | null | undefined
): string | null => {
  const { formatMessage } = useMelioIntl();

  switch (deliveryPreferenceType) {
    case 'rtp':
      return formatMessage('utils.deliveryMethodPreference.rtpOrganization.label');
    default:
      return null;
  }
};

export type InternationalDeliveryMethod = 'international-account' | 'international-fx';

export const useGetSupportedInternationalDMs = ({
  vendorCurrency,
  businessType,
}: {
  vendorCurrency?: Vendor['currency'];
  businessType?: OrganizationBusinessType | null;
}) => {
  const isInternationalFxEnabled = useInternationalFxFeature();

  const supportedInternationalDeliveryMethod: InternationalDeliveryMethod[] = [];

  const isUsdCurrency = vendorCurrency === 'USD';
  if (!vendorCurrency || isUsdCurrency) {
    supportedInternationalDeliveryMethod.push('international-account');
  }
  if (isInternationalFxEnabled && (!vendorCurrency || !isUsdCurrency) && isBusinessTypeAllowedForFX(businessType)) {
    supportedInternationalDeliveryMethod.push('international-fx');
  }
  return supportedInternationalDeliveryMethod;
};

export const useGetDeliveryMethodLabelText = (
  deliveryMethod: DeliveryMethod,
  timeline: Array<PaymentTimelineItem>,
  deliveryMethodPreference?: DeliveryPreferenceType | null
): string => {
  const { formatMessage } = useMelioIntl();
  const deliveryPreferenceLabelText = useGetDeliveryPreferenceLabelText(deliveryMethodPreference);

  switch (deliveryMethod.type) {
    case 'bank-account': {
      if (deliveryPreferenceLabelText) {
        return deliveryPreferenceLabelText;
      }
      return formatMessage('utils.deliveryMethod.bankAccount.label');
    }
    case 'paper-check': {
      const tracingNumber = extractTracingNumber(timeline);
      if (tracingNumber) {
        return formatMessage('utils.deliveryMethod.paperCheck.labelScheduled', { tracingNumber });
      }
      return formatMessage('utils.deliveryMethod.paperCheck.label');
    }
    case 'domestic-wire-account':
      return formatMessage('utils.deliveryMethod.domesticWireAccount.label');
    case 'virtual-account':
      return formatMessage('utils.deliveryMethod.unilateral.label');
    case 'card':
      return formatMessage(
        `utils.deliveryMethod.${(deliveryMethod.details as Card).type === 'debit' ? 'debitCard' : 'creditCard'}.label`
      );
    case 'virtual-card':
      return formatMessage('utils.deliveryMethod.virtualCard.label');
    case 'biller-account':
      return formatMessage('utils.deliveryMethod.billerAccount.label');
    case 'managed-account': {
      const managedDeliveryMethodType = getManagedAccountDeliveryMethodType(deliveryMethod.details);
      switch (managedDeliveryMethodType) {
        case 'bank-account':
          return formatMessage('utils.deliveryMethod.bankAccount.managed.label');
        case 'paper-check':
          return formatMessage('utils.deliveryMethod.paperCheck.managed.label');
        default:
          return formatMessage('utils.deliveryMethod.billerAccount.label');
      }
    }
    case 'international-account':
      return formatMessage('utils.deliveryMethod.internationalAccount.label');
    default:
      return 'unsupported';
  }
};

export const useDeliveryMethodIconOrBrandSymbol = (deliveryMethod: DeliveryMethod) => {
  const { settings } = useConfig();

  const iconKey = getDeliveryMethodIconKey(deliveryMethod, settings.icons.deliveryMethod);

  // TODO: replace with utility from @melio/platform-api - https://meliorisk.atlassian.net/browse/ME-24864
  const isBrandSymbol = Object.keys(brandSymbolsMap).includes(iconKey as BrandSymbolKey);

  return isBrandSymbol ? (
    <BrandSymbol type={iconKey as BrandSymbolKey} />
  ) : (
    <Icon type={iconKey as IconKey} color="inherit" />
  );
};

const extractTracingNumber = (timeline: Array<PaymentTimelineItem>) =>
  timeline.find(
    (item) =>
      item.flow === PaymentTimelineItemFlowEnum.Deliver &&
      item.tracingNumber &&
      item.status !== PaymentTimelineItemStatusEnum.Failed
  )?.tracingNumber;

export const isSelectedDmRequiresConfirmation = ({
  enablePromoteAch,
  selectedDeliveryMethod,
}: {
  enablePromoteAch: boolean;
  selectedDeliveryMethod?: DeliveryMethod;
}): boolean => enablePromoteAch && !!selectedDeliveryMethod?.requireConfirmationForPayment;
